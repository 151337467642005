import React from 'react'
import { useFanmeBackendGet } from '@/libs/fanme_backend'
import { useNotificationStore } from '@/utils/hooks/useNotification'
import { StyledBadge } from './index.style'

interface IBadgeProps {
  from?: string
}
const BlogArticleBadge = ({ from }: IBadgeProps) => {
  const { isNewNotification } = useNotificationStore()
  const { data: badgeData } = useFanmeBackendGet<any>('/fanme/api/creators/self/article/badge', {
    auth: true,
  })

  if (!from && (!badgeData || badgeData.count <= 0)) return null
  if (from === 'top' && badgeData?.count <= 0 && !isNewNotification) return null
  return <StyledBadge />
}
export default BlogArticleBadge
