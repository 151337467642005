import React from 'react'
import Link from 'next/link'
import { DEFAULT_NOTIFICATION_TITLE } from '@/constants'
import { Notification } from '../NotificationList/index'
import {
  StyledNoticeIcon,
  StyledDetail,
  StyledTitle,
  StyledDiff,
  StyledDescription,
  StyledListItem,
} from './index.style'
import { useNotificationIcon } from './useNotificationIcon'

interface Props {
  item: Notification
}

const NotificationListItem = ({ item }: Props) => {
  const notificationIcon = useNotificationIcon(item)

  const renderContent = (
    <>
      <StyledNoticeIcon>{notificationIcon}</StyledNoticeIcon>
      <StyledDetail>
        <StyledTitle>
          {item.title ?? DEFAULT_NOTIFICATION_TITLE}
          <StyledDiff>{item.diff}</StyledDiff>
        </StyledTitle>
        <StyledDescription>{item.content}</StyledDescription>
      </StyledDetail>
    </>
  )

  return item.navigationUrl ? (
    <Link href={item.navigationUrl} rel="noopener noreferrer" target="_blank">
      <StyledListItem>{renderContent}</StyledListItem>
    </Link>
  ) : (
    <StyledListItem>{renderContent}</StyledListItem>
  )
}

export default NotificationListItem
