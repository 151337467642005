/*
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=9921408087127633759-AP&hl=ja&client_type=gtag#add_to_wishlist
 */
import { SellerAppItem, SellerAppItemPrice } from '@torihada-inc/miniapps/dist/Seller'
import {
  AddPaymentInfo,
  AddShippingInfo,
  AddToCart,
  AddToWishlist,
  BeginCheckout,
  Currency,
  GenerateLead,
  Item,
  LoginMethod,
  PaymentType,
  Purchase,
  Refund,
  RemoveFromCart,
  SelectItem,
  SelectPromotion,
  Share,
  ShareMethod,
  ViewCart,
  ViewItem,
  ViewItemList,
  ViewPromotion,
} from '@/types/GAEvents'

declare global {
  interface Window {
    dataLayer: Record<string, any>[]
  }
}

export const ga4SellerAppItemsToItems = (
  sellerAppItems: SellerAppItem[],
  sellerAppItemPrices: SellerAppItemPrice[],
): Item[] => {
  // map with index
  return sellerAppItems?.map((sellerAppItem, index) => {
    const sellerAppItemPrice = sellerAppItemPrices[index]
    return {
      item_id: sellerAppItem.sellerAppItemId?.toString() ?? '',
      item_name: sellerAppItem.name ?? '',
      quantity: 1,
      price: sellerAppItemPrice.price,
      currency: Currency.JPY,
    }
  })
}

export const ga4SumItemsPrice = (sellerAppItemPrices: SellerAppItemPrice[]): number => {
  return sellerAppItemPrices.reduce((sum, sellerAppItemPrice) => {
    return sum + (sellerAppItemPrice.price ?? 0)
  }, 0)
}

/*
 * Tracking用ユーザーIDの設定
 */
export const ga4SetUserId = (userId: string) => {
  window.dataLayer?.push({
    user_id: userId,
    transport: 'beacon',
  })
}

/*
 * クレカ、コンビニ払いの設定をした場合に送る
 */
export const ga4AddPaymentInfo = (paymentType: PaymentType, value: number, items: Array<Item>) => {
  const payload: AddPaymentInfo = {
    currency: Currency.JPY,
    value: value,
    coupon: undefined,
    payment_type: paymentType.toString(),
    items: items,
  }
  window.gtag('event', 'add_payment_info', payload)
}

/*
 * クレカの変更をした場合に送る
 */
export const ga4UpdatePaymentInfo = (paymentType: PaymentType, items: Array<Item>) => {
  window.gtag('event', 'update_payment_info', {
    items: items,
    payment_type: paymentType.toString(),
    transport: 'beacon',
  })
}

/*
 * クレカの変更をした場合に送る
 */
export const ga4DeletePaymentInfo = (paymentType: PaymentType, items: Array<Item>) => {
  window.gtag('event', 'delete_payment_info', {
    items: items,
    payment_type: paymentType.toString(),
    transport: 'beacon',
  })
}

/*
 * 送付先住所の入力が終わったら送る
 */
export const ga4AddShippingInfo = (value: number, items: Array<Item>) => {
  const payload: AddShippingInfo = {
    currency: Currency.JPY,
    value: value,
    coupon: undefined,
    shipping_tier: undefined,
    items: items,
  }
  window.gtag('event', 'add_shipping_info', payload)
}

/*
 * カートに追加された場合に送る。itemsはカートに入れられた商品だけを設定。
 */
export const ga4AddToCart = (value: number, items: Array<Item>) => {
  const payload: AddToCart = {
    currency: Currency.JPY,
    value: value,
    items: items,
  }
  window.gtag('event', 'add_to_cart', payload)
}

/*
 * 未使用
 */
export const ga4AddToWishlist = (value: number, items: Array<Item>) => {
  const payload: AddToWishlist = {
    currency: Currency.JPY,
    value: value,
    items: items,
  }
  window.gtag('event', 'add_to_wishlist', payload)
}

/*
 * 商品詳細から購入ボタンを押したときに送る
 */
export const ga4BeginCheckout = (value: number, items: Array<Item>) => {
  const payload: BeginCheckout = {
    currency: Currency.JPY,
    value: value,
    coupon: undefined,
    items: items,
  }
  window.gtag('event', 'begin_checkout', payload)
}

/*
 * 未使用
 */
export const ga4EarnVirtualCurrency = (currency: string, value: number) => {
  window.gtag('event', 'earn_virtual_currency', {
    currency: currency,
    value: value,
    transport: 'beacon',
  })
}

/*
 * 未使用
 */
export const ga4GenerateLead = (value: number) => {
  const payload: GenerateLead = {
    currency: Currency.JPY,
    value: value,
  }
  window.gtag('event', 'generate_lead', payload)
}

/*
 * 未使用
 */
export const ga4JoinGroup = (group: string) => {
  window.gtag('event', 'join_group', {
    group: group,
    transport: 'beacon',
  })
}

/*
 * 未使用
 */
export const ga4LevelEnd = (level: string) => {
  window.gtag('event', 'level_end', {
    level: level,
    transport: 'beacon',
  })
}

/*
 * 未使用
 */
export const ga4LevelStart = (level: string) => {
  window.gtag('event', 'level_start', {
    level: level,
    transport: 'beacon',
  })
}

/*
 * 未使用
 */
export const ga4LevelUp = (level: string) => {
  window.gtag('event', 'level_up', {
    level: level,
    transport: 'beacon',
  })
}

/*
 * 明示的にログイン（要ユーザーアクション）された場合に送る
 */
export const ga4Login = (method: LoginMethod) => {
  window.gtag('event', 'login', {
    method: method,
    transport: 'beacon',
  })
}

/*
 * 未使用
 */
export const ga4PostScore = (score: number) => {
  window.gtag('event', 'post_score', {
    score: score,
    transport: 'beacon',
  })
}

/*
 * 購入実行時に送る
 * コンビニ払いのときも送る
 */
export const ga4Purchase = (
  purhcaserId: string,
  value: number,
  shipping: number,
  items: Array<Item>,
) => {
  const payload: Purchase = {
    currency: Currency.JPY,
    transaction_id: purhcaserId + Date.now(),
    value: value,
    shipping: shipping,
    items: items,
  }
  window.gtag('event', 'purchase', payload)
}

/*
 * 未使用
 */
export const ga4Refund = (
  transactionId: string,
  value: number,
  shipping: number,
  items: Array<Item>,
) => {
  const payload: Refund = {
    currency: Currency.JPY,
    transaction_id: transactionId,
    value: value,
    shipping: shipping,
    items: items,
  }
  window.gtag('event', 'refund', payload)
}

/*
 * カートから商品が除去された場合に送る
 * ※itemsは除去された商品情報
 */
export const ga4RemoveFromCart = (value: number, items: Array<Item>) => {
  const payload: RemoveFromCart = {
    currency: Currency.JPY,
    value: value,
    items: items,
  }
  window.gtag('event', 'remove_from_cart', payload)
}

/*
 * 未使用
 */
export const ga4Search = (searchTerm: string) => {
  window.gtag('event', 'search', {
    search_term: searchTerm,
    transport: 'beacon',
  })
}

/*
 * 未使用
 */
export const ga4SelectContent = (contentType: string, contentId: string) => {
  window.gtag('event', 'select_content', {
    content_type: contentType,
    content_id: contentId,
    transport: 'beacon',
  })
}

/*
 * 未使用
 */
export const ga4SelectItem = (itemListId: string, itemListName: string, items: Array<Item>) => {
  const payload: SelectItem = {
    items: items,
    item_list_id: itemListId,
    item_list_name: itemListName,
  }
  window.gtag('event', 'select_item', payload)
}

/*
 * 未使用
 */
export const ga4SelectPromotion = (
  creativeName: string,
  creativeSlot: string,
  promotionId: string,
  promotionName: string,
  items: Array<Item>,
) => {
  const payload: SelectPromotion = {
    items: items,
    creative_name: creativeName,
    creative_slot: creativeSlot,
    promotion_id: promotionId,
    promotion_name: promotionName,
  }
  window.gtag('event', 'select_promotion', payload)
}

/*
 * 未使用
 */
export const ga4Share = (method: ShareMethod, contentType: string, itemId: string) => {
  const payload: Share = {
    method: method,
    content_type: contentType,
    item_id: itemId,
  }
  window.gtag('event', 'share', payload)
}

/*
 * サインアップ(新規登録)した場合に送る
 */
export const ga4SignUp = (method: LoginMethod) => {
  window.gtag('event', 'sign_up', {
    method: method,
    transport: 'beacon',
  })
}

/*
 * 未使用
 */
export const ga4SpendVirtualCurrency = (
  value: number,
  virtualCurrencyName: string,
  itemName: string,
) => {
  window.gtag('event', 'spend_virtual_currency', {
    value: value,
    virtual_currency_name: virtualCurrencyName,
    item_name: itemName,
    transport: 'beacon',
  })
}

/*
 * 未使用
 */
export const ga4TutorialBegin = () => {
  window.gtag('event', 'tutorial_begin', {
    transport: 'beacon',
  })
}

/*
 * 未使用
 */
export const ga4TutorialComplete = () => {
  window.gtag('event', 'tutorial_complete', {
    transport: 'beacon',
  })
}

/*
 * 未使用
 */
export const ga4UnlockAchievement = (achievementId: string) => {
  window.gtag('event', 'unlock_achievement', {
    achievement_id: achievementId,
    transport: 'beacon',
  })
}

/*
 * カートを表示した時のitem一覧を送る
 */
export const ga4ViewCart = (value: number, items: Array<Item>) => {
  const payload: ViewCart = {
    currency: Currency.JPY,
    value: value,
    items: items,
  }
  window.gtag('event', 'view_cart', payload)
}

/*
 * 商品ページで表示された商品を送る
 */
export const ga4ViewItem = (value: number, items: Array<Item>) => {
  const payload: ViewItem = {
    currency: Currency.JPY,
    value: value,
    items: items,
  }
  window.gtag('event', 'view_item', payload)
}

/*
 * 商品一覧ページで表示された商品を送る
 * itemListId: seller_app_id
 * itemListName: seller_app_name
 */
export const ga4ViewItemList = (itemListId: string, itemListName: string, items: Array<Item>) => {
  const payload: ViewItemList = {
    items: items,
    item_list_id: itemListId,
    item_list_name: itemListName,
  }
  window.gtag('event', 'view_item_list', payload)
}

/*
 * 未使用
 */
export const ga4ViewPromotion = (
  creativeName: string,
  creativeSlot: string,
  promotionId: string,
  promotionName: string,
  items: Array<Item>,
) => {
  const payload: ViewPromotion = {
    creative_name: creativeName,
    creative_slot: creativeSlot,
    promotion_id: promotionId,
    promotion_name: promotionName,
    items: items,
  }
  window.gtag('event', 'view_promotion', payload)
}
