import styled from '@emotion/styled'
import { Colors } from '@/constants/styles/color'

const HistoryItemContainer = styled.div`
  display: grid;
  grid-template-columns: calc(100% - 24px) 16px;
  align-items: center;
  padding: 0px 0px 8px;
  border-bottom: 1px solid ${Colors.VERY_LIGHT_GRAY};
  margin-bottom: 16px;
  column-gap: 8px;
`

const FieldTitle = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
  max-width: 60%;
  text-align: left;
`

const FieldHistoryData = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
  max-width: 60%;
`

const FieldItemContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const FieldHistoryTitle = styled.div`
  margin-left: 8px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
`
const FieldData = styled.div`
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  color: ${Colors.GRAY};
`

const IconData = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const IconName = styled.div`
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  color: ${Colors.GRAY};
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
`

const FieldValue = styled.div<{ isMinus?: boolean }>`
  color: ${props => (props.isMinus ? Colors.ERROR : Colors.PRIMARY_GRAY)};
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
`

const FieldAppIcon = styled.img`
  border-radius: 8px;
  object-fit: cover;
`

const FieldIcon = styled.img`
  object-fit: cover;
  border-radius: 50%;
`

const FieldItemData = styled.div`
  display: grid;
  grid-template-columns: 40px calc(100% - 40px);
`

const ItemTitele = styled.div<{ isError?: boolean }>`
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${props => (props.isError ? Colors.RED : Colors.PRIMARY_GRAY)};
`

export {
  HistoryItemContainer,
  FieldTitle,
  FieldHistoryData,
  FieldItemContainer,
  FieldHistoryTitle,
  FieldData,
  IconData,
  IconName,
  FieldValue,
  FieldAppIcon,
  FieldIcon,
  FieldItemData,
  ItemTitele,
}
