import { create } from 'zustand'

interface NotificationState {
  notificationCount: number
  isNewNotification: boolean
  setNotificationCount: (count: number) => void
  setNewNotification: (isNew: boolean) => void
}

export const useNotificationStore = create<NotificationState>()(set => ({
  notificationCount: 0,
  isNewNotification: false,
  setNotificationCount: (count: number) => set({ notificationCount: count }),
  setNewNotification: (isNew: boolean) => set({ isNewNotification: !!isNew }),
}))
