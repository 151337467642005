import React, { useState } from 'react'
import NotificationIconGray from '@/assets/img/icons/Notification.svg'
import { Colors } from '@/constants/styles/color'
import { useNotificationStore } from '@/utils/hooks/useNotification'
import NotificationList from '../NotificationList'
import { StyledMyMenuItem, StyledMyMenuItemName, StyledMyMenuNotification } from '../index.style'

interface Props {
  accountIdentity: string
}

const Notification = ({ accountIdentity }: Props) => {
  const { isNewNotification } = useNotificationStore()
  const [visible, setVisible] = useState(false)

  const onClose = () => {
    setVisible(false)
  }

  return (
    <>
      <StyledMyMenuItem onClick={() => setVisible(true)}>
        <NotificationIconGray width={20} height={20} fill={Colors.BLACK} />
        <StyledMyMenuItemName>通知</StyledMyMenuItemName>
        {isNewNotification && <StyledMyMenuNotification />}
      </StyledMyMenuItem>

      <NotificationList accountId={accountIdentity} visible={visible} onClose={onClose} />
    </>
  )
}

export default Notification
