import React, { useEffect, useState } from 'react'
import getConfig from 'next/config'
import { MiniApp } from '@torihada-inc/miniapps'
import { SellerHistoryListAccountResponse } from '@torihada-inc/miniapps/dist/repositories/user/SellerAccountHistoryRepository'
import numeral from 'numeral'
import moment from 'moment'
import FullModal from '@/components/atoms/Dialog/FullModal'
import FullModalHeader from '@/components/molecules/Header/FullModalHeader'
import { InfiniteScrollContainer } from '@/components/templates/InfiniteScroll'
import { useAuth } from '@/contexts/AuthContext'
import { getMiniAppInfo } from '@/pages/api/miniapp'
import { getAccountIdentity } from '@/pages/api/creator'
import ARROW_RIGHT from '@/assets/img/icons/Arrow_Right.svg'
import LOGO_ICON from '@/assets/img/logo/LOGO_Icon_Account.svg'
import { useIsMounted } from '@/utils/hooks/is_mounted'
import { formatDate } from '@/libs/utils'
import { useFanmeBackendPost } from '@/libs/fanme_backend'
import MoneyManagementDetail from '../MoneyManagementDetail'
import {
  FieldAppIcon,
  FieldData,
  FieldHistoryData,
  FieldHistoryTitle,
  FieldIcon,
  FieldItemContainer,
  FieldItemData,
  FieldTitle,
  FieldValue,
  HistoryItemContainer,
  IconData,
  IconName,
  ItemTitele,
} from './index.style'

const status = {
  LAPSE: '売上金失効',
  WITHDRAWAL: '売上金銀行振込エラー手数料',
  WITHDRAWAL_REQUEST: '売上申請',
}

const limit = 20
let itemCurrentListLen = 0
let isFetchMore = true

interface Props {
  accountId: string
  visibleSalesBalanceHistory: boolean
  onClose: () => void
}

const { publicRuntimeConfig } = getConfig()

const MoneyManagementHistory = ({ accountId, visibleSalesBalanceHistory, onClose }: Props) => {
  const isMounted = useIsMounted()
  const { token } = useAuth()
  const [miniApp, setMiniApp] = useState<MiniApp | undefined>()
  const [historyAccount, setHistoryAccount] = useState<SellerHistoryListAccountResponse>()
  const [visibleDetail, setVisibleDetails] = useState(false)
  const [accountIdentities, setAccountIdentities] = useState<any>({})
  const [tableName, setTableName] = useState<any>('')
  const [tableId, setTableId] = useState<any>('')
  const [currentList, setCurrentList] = useState<any[]>([])
  const [itemCurrentList, setItemCurrentList] = useState<any[]>([])
  const [isRequest, setIsRequest] = useState(true)

  const initMiniApp = async () => {
    const res: any = await getMiniAppInfo(accountId)

    const miniAppData = new MiniApp(publicRuntimeConfig.PAYMENT_URL, res.user_uid, token as string)
    setMiniApp(miniAppData)
  }
  // /seller/account/history/list
  const historyList = useFanmeBackendPost<SellerHistoryListAccountResponse>(
    `${publicRuntimeConfig.PAYMENT_URL}/seller/account/history/list`,
    {
      seller_user_id: accountId,
    },
  )
  useEffect(() => {
    if (historyList.data) {
      setHistoryAccount(historyList.data)
    }
  }, [historyList.data])

  useEffect(() => {
    if (token && visibleSalesBalanceHistory) {
      initMiniApp()
    }
  }, [accountId, token, visibleSalesBalanceHistory])

  const renderHeader = () => <FullModalHeader title="売上金明細" onBack={onClose} />

  const activityHistory = historyAccount?.activity_history || []
  const itemHistory = historyAccount?.item_history || []

  const sortHistory = [...activityHistory, ...itemHistory].sort((a, b) => {
    const aa = moment(a.update_at).unix()
    const bb = moment(b.update_at).unix()
    if (aa == bb) return 0
    return aa < bb ? 1 : -1
  })
  const fetchMore = () => {
    const currentLength = currentList.length
    const newList = sortHistory.slice(0, currentLength + limit)

    const newItemHistory =
      sortHistory.length > 0 ? sortHistory.slice(0, itemCurrentListLen + limit) : []
    setCurrentList(newList)
    setItemCurrentList(newItemHistory)
    itemCurrentListLen = newItemHistory.length

    if (!isFetchMore) {
      setIsRequest(false)
      return
    } else {
      const startPoint = newItemHistory.length <= limit ? 0 : newItemHistory.length
      const currentRequestItemHistory = sortHistory.slice(startPoint, startPoint + limit)

      currentRequestItemHistory.map((item: any) => {
        if (item.purchaser_user_id) {
          getAccountIdentity(item.purchaser_user_id).then(accountIdentityRes => {
            accountIdentities[item.purchaser_user_id!] = accountIdentityRes
            if (isMounted()) {
              setAccountIdentities({ ...accountIdentities, accountIdentities })
            }
          })
        }
      })
      isFetchMore = newItemHistory.length < sortHistory.length
    }
  }

  const renderContent = () => (
    <InfiniteScrollContainer fetchMore={fetchMore} isRequest={isRequest}>
      {currentList.map((it, i) => {
        if (it.type !== undefined) {
          // activity history しか type がない
          if (
            it.nominal === status.LAPSE ||
            it.nominal === status.WITHDRAWAL ||
            it.nominal === status.WITHDRAWAL_REQUEST
          ) {
            return (
              <HistoryItemContainer
                key={`${it.id}-${i}`}
                onClick={() => {
                  setVisibleDetails(true)
                  setTableName('AccountActivities')
                  setTableId(it.id)
                }}
              >
                <FieldItemContainer>
                  <FieldTitle>
                    <ItemTitele
                      isError={it.nominal !== status.LAPSE || it.nominal !== status.WITHDRAWAL}
                    >
                      {it.nominal}
                    </ItemTitele>
                    <FieldData>{formatDate(it.update_at, 9)}</FieldData>
                    <IconData>
                      <LOGO_ICON width={20} height={20} />
                      <IconName>FANME運営</IconName>
                    </IconData>
                  </FieldTitle>
                  {/* typeがwithdrawalの場合は出金した場合なのでマイナスで金額を表示 */}
                  <FieldValue isMinus={it.type === 'withdrawal'}>{`${
                    it.type === 'withdrawal' && '-'
                  }¥${numeral(it.amount).format('0,0')}`}</FieldValue>
                </FieldItemContainer>
                <ARROW_RIGHT width={16} height={16} />
              </HistoryItemContainer>
            )
          } else {
            return null
          }
        } else if (it.purchaser_user_id) {
          const accountIdentity = accountIdentities[it.purchaser_user_id]
          return (
            <HistoryItemContainer
              key={`${it.id}-${i}`}
              onClick={() => {
                setVisibleDetails(true)
                setTableName('Transactions')
                setTableId(it.transaction_id)
              }}
            >
              <FieldItemContainer>
                <FieldHistoryData>
                  <FieldItemData>
                    <FieldAppIcon width="40" height="40" src={it.icon} />
                    <FieldHistoryTitle>
                      <ItemTitele>{it.name}</ItemTitele>
                      <FieldData>{formatDate(it.update_at, 9)}</FieldData>
                    </FieldHistoryTitle>
                  </FieldItemData>
                  <IconData>
                    <FieldIcon width="20" height="20" src={accountIdentity?.icon} />
                    <IconName>{accountIdentity?.name}</IconName>
                  </IconData>
                </FieldHistoryData>
                <FieldValue>¥{numeral(it.amount).format('0,0')}</FieldValue>
              </FieldItemContainer>
              <ARROW_RIGHT width={16} height={16} />
            </HistoryItemContainer>
          )
        } else {
          return null
        }
      })}
    </InfiniteScrollContainer>
  )

  return (
    <>
      <FullModal
        header={renderHeader()}
        content={renderContent()}
        visible={visibleSalesBalanceHistory}
        zIndex={10000}
        bodyPadding="16px"
      />
      <MoneyManagementDetail
        accountId={accountId}
        visible={visibleDetail}
        tableName={tableName}
        tableId={tableId}
        onClose={() => setVisibleDetails(false)}
      />
    </>
  )
}

export default MoneyManagementHistory
